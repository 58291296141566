@import '@/styles/colors.module';

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 64px;
  padding-top: 25px;

  &:not(:last-child) {
    padding-bottom: 25px;
    border-bottom: 1px solid $border_light_gray;
  }

  .columns {
    .column {
      .bold {
        font-weight: 500;
      }

      .gray {
        color: $light_gray;
      }
    }
  }
}
