@import '@/styles/colors.module';

.table {
  &WithVerticalScroll {
    max-height: 600px;
    overflow-y: auto;
  }

  .purchaseNumberRow {
    td {
      background-color: $white !important;
      font-weight: 500;
      font-size: 18px;
    }
  }

  .expandedRow {
    td {
      background-color: $gray !important;
    }
  }

  .name {
    max-width: 308px;
  }
}
