@import '@/styles/colors.module';

.header {
  padding: 0 32px;
}

.table {
  margin-top: 16px;

  .bold {
    font-weight: 500;
  }

  .disabledRow {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
    transition: .2s ease-in-out opacity;
  }

  .ellipsisCell {
    max-width: 220px;
  }
}

