.group {
  display: flex !important;
  flex-direction: column !important;
  gap: 8px;

  :global {
    .ant-radio {
      margin-top: 3px !important;
      align-self: start !important;
    }
  }
}