@import '@/styles/colors.module';

.price {
  white-space: nowrap;

  .maxIncreasePriceRatioCoeff {
    color: $green;
  }

  .maxIncreasePricePeriodRatioCoeff {
    color: $blue;
  }
}

.coefficientPopOverContent {
  max-width: 300px;
  text-align: center;
}

.coefficients {
  cursor: pointer;
}