@import '@/styles/colors.module';

.navigation {
  display: flex;
  flex-direction: column;

  .link {
    display: flex;
    align-items: center;
    gap: 4px;
    white-space: nowrap;

    &.notActive {
      &:not(:hover) {
        color: black;
      }
    }
  }

  &.navigationGPB {
    height: 56px;
    justify-content: space-between;

    .parentList {
      .link {
        font-weight: 500;
        font-size: 15px;
        height: 24px;
      }
    }

    .childrenList {
      padding-top: 8px;

      .link {
        font-size: 12px;
        font-weight: 500;
        height: 24px;
      }
    }
  }

  &PIRSON {
    .parentList {
      .link {
        font-weight: 600;
        font-size: 14px;
        height: 24px;
      }
    }

    .childrenList {
      padding-top: 8px;

      .link {
        font-size: 12px;
        font-weight: 500;
        height: 24px;
      }
    }
  }
}
