@import "@/styles/colors.module";

.bold {
  font-weight: 500;
}

.coeffs {
  cursor: pointer;
}

.green {
  color: $green;
}

.blue {
  color: $blue;
}