@import '@/styles/colors.module';

.infoValue {
  font-size: 16px;
  line-height: 22px;
}

.infoList {
  max-width: 700px;
}

.input {
  width: 140px !important;
}
