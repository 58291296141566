@import "@/styles/colors.module";

.warning {
  border-radius: 4px;
  padding: 8px 0;

  &.compatibility {
    border: 1px solid $gold;
    background: $gray;

    &.verticalBorder {
      border-top: 1px solid $gold;
      border-bottom: 1px solid $gold;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }

  .text {
    font-size: 14px;
    line-height: 18px !important;
    color: $light_gray;
  }
}
