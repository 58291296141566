@import '@/styles/colors.module';

.select {
  width: 416px !important;
}

.form {
  margin-left: 32px !important;
  margin-bottom: 24px;
}
