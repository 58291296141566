@import '@/styles/colors.module';

.headerLink {
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
}

.content {
  .text {
    font-size: 16px;
  }

  .mdText {
    white-space: pre-wrap;
  }

  .note {
    padding-left: 16px;
    border-left: 2px solid $gold;
    font-weight: 500;
  }

  .caption {
    font-size: 18px;
    font-weight: 500;
  }
}
