@import "@/styles/colors.module";

.modal {
  $vertical-padding: 20px;
  $horizontal-padding: 24px;

  :global {
    .ant-modal-close {
      width: 24px !important;
      height: 24px !important;
    }
  }

  .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;

    &Gray {
      background-color: $gray !important;

      .body {
        padding-bottom: 4px !important;
      }
    }
  }

  .header {
    :global {
      .ant-modal-title {
        margin-right: 36px !important;
        padding: $vertical-padding $horizontal-padding !important;
      }
    }
  }

  .body {
    padding: 16px $horizontal-padding !important;
    background-color: $gray;
    max-height: calc(100vh - 200px); // 200px == header-height + footer-height + небольшой запас
    overflow-y: auto;

    &White {
      padding-block: 0 !important;
      background-color: $white;
    }
  }

  .footer {
    padding: $vertical-padding $horizontal-padding !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
  }
}