@import "@/styles/colors.module";

.values {
  column-gap: 20px;
  row-gap: 8px;

  :global {
    .ant-checkbox-wrapper, .ant-radio-wrapper {
      color: $light_blue !important;

      &:hover {
        color: $black !important;
      }

      &-checked {
        color: $black !important;
        font-weight: 500;
      }
    }
  }
}