@import '@/styles/colors.module';
@import "@/styles/mixins";

.wrapper {
  padding: 28px 32px;
  background-color: $white;
  @include sticky-element();

  .price {
    font-size: 14px;

    &Label {
      color: $light_gray;
    }
  }

}
