@import '@/styles/colors.module';


.content {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .top {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .topDrug {
    border-bottom: 1px solid $border_gray;
    padding-bottom: 12px;
  }

  .text,
  .textShort {
    width: 250px;
    flex-shrink: 0;
    font-weight: 400;
    color: $light_gray;
    font-size: 14px;
  }

  .textShort {
    width: 126px;
  }

  .select {
    width: 560px;
  }

  .itemNameInput {
    width: 338px;
  }

  .itemNameInputShort {
    width: 260px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }

  .isDrugText {
    color: $light_gray;
    font-size: 12px;
  }

  .isDrugTextChecked {
    color: $black;
    font-weight: 500;
  }

  .input {
    width: 260px;
  }

  .datePicker {
    width: 130px;
    height: 40px;
  }

  .priceForCalculation {
    display: flex;
    padding-top: 12px;
    border-top: 1px solid $border_gray;
    gap: 32px;

    .title {
      width: 250px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}
