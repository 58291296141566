@import "@/styles/colors.module";

.filter {
  margin-top: 16px;
  margin-bottom: 32px;
}

.divider {
  min-width: 1px;
  background-color: $border_gray;
  align-self: stretch;
}