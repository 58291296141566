@import '@/styles/colors.module';

.action {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  width: fit-content;
}

.actionActive {
  opacity: 0.5;
}

.checkIcon {
  color: $green;
}

.vIcon {
  color: inherit;
}

.vIconActive {
  transform: rotate(-180deg);
}

.npaList {
  column-gap: 4px;
  row-gap: 8px;
  max-width: 200px;
}