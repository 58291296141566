@import "@/styles/colors.module";

.wrapper {
  font-size: 16px;
  line-height: 22px;

  .label {
    width: 245px;
    min-width: 245px;
  }
}

.wrapperInCard {
  font-size: 14px;
  line-height: 17px;

  .label {
    white-space: nowrap;
    display: inline;
  }

  .row {
    display: block;
  }
}

.label {
  color: $light_gray;
}
